import React, { memo } from 'react'

// Libraries
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { PropTypes } from "prop-types";

// Components
import FooterMenu, { Footer} from "../../Components/Footers/Footer"

import karlRayLogoCircle from "./img/karl-ray-logo-circle.png";
import paperboatLogo from "./img/paperboat.svg";
// Data
import FooterData from './FooterData';

const KRFooter = (props) => {
    return (
        <Footer theme={props.theme} className={`${props.className ? ` ${props.className}` : ""}`}>
            <div className="py-[5%] lg:py-[95px] md:py-[50px]">
                <Container>
                    <Row>
                        <FooterMenu data={FooterData.slice(0, 2)} lg={3} sm={6} className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]" titleClass="capitalize" />
                        <Col lg={3} sm={6} className="xs:mb-[25px]">
                            <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">Karl Ray Distribution</span>
                            <p className="w-[85%] mb-[15px]">7th Mile Royal Road, Triolet, Mauritius</p>
                            <p className="w-[85%] mb-[15px]">Royal Road, Solitude, Mauritius</p>
                            <div><i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>+230 270 04 13</div>
                            <div><i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>+230 270 04 20</div>
                            <div><i className="feather-mail text-sm mr-[10px] text-themecolor"></i><a aria-label="mail" href="mailTo:karl.goldfinger@gmail.com">karl.goldfinger@gmail.com</a></div>
                        </Col>
                        <Col lg={3} sm={6}>
                        <Link aria-label="homepage" to="/" className="sm:flex sm:justify-center">
                                <img alt="Karl Ray circle logo" src={karlRayLogoCircle} width="200" height="200" />
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="py-[40px] border-t border-[#ffffff1a]">
                <Container>
                    <Row>
                        <Col md={3} className="sm:mb-[20px]">
                            <Link 
                                to="https://paperboat.io/"
                                aria-label="paperboat" 
                                rel="noreferrer" 
                                className="hover:text-white paperboat-logo" 
                                target="_blank"
                            >
                                <img alt="Paperboat logo" src={paperboatLogo} width="100" height="100" />
                            </Link>
                        </Col>
                        <Col md={6} className="flex justify-center items-center text-center sm:mb-[20px]">
                            <p className="mb-0">&copy; 2023 – {new Date().getFullYear()} Copyright Karl Ray Distribution Ltd</p>
                        </Col>
                        <Col md={3} className="text-right sm:text-center">
                           
                        </Col>
                    </Row>
                </Container>
            </div>
        </Footer>
    )
}

KRFooter.defaultProps = {
    data: FooterData,
    className: "bg-darkgray text-[#828282]",
    logo: "/assets/img/webp/logo-white.webp",
    theme: "light"
}

KRFooter.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string,
}

export default memo(KRFooter)
