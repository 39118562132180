import React from "react";

import KRFooter from "../KRFooter/KRFooter";
import { fadeIn } from "../../Functions/GlobalAnimations";
import SideButtons from "../../Components/SideButtons";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import compostMachine from "./img/metprop-compost-machine.png";

// Header
import CommonHeader from "../CommonComponents/CommonHeader";
import ContactSection from "../ContactSection";

const MetpropCompostMachine = (props) => {
  return (
    <div style={props.style}>
      {/* Header Start */}
      <CommonHeader />
      {/* Header End */}
      <SideButtons />
      {/* Parallax Section Start */}
      <m.div className="overflow-hidden py-[40px]" {...fadeIn}>
        <Container className="relative h-full">
          <Row className="justify-center h-[100px] sm:h-[250px] m-0 items-stretch w-full">
            <Col
              xl={7}
              lg={7}
              md={9}
              className="flex flex-col justify-center font-serif text-center xs:px-0"
            >
              <h6 className="text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] mb-[15px] leading-[20px] inline-block text-xmd">
              Turn waste into rich compost within 24 hours with the Metprop compost machine.
              </h6>
            </Col>
          </Row>
        </Container>
      </m.div>
      {/* Parallax Section End */}

      {/* Section Start*/}
      <div className="container mx-auto px-4 mb-7">
        <div className="flex sm:flex-col items-center gap-4 space-y-4 md:space-y-0 md:space-x-6 mb-6">
          <div>
            <h2 class="text-darkgray font-light">
              <span class="inline-block font-semibold border-b-2 border-fastblue">
                METPROP
              </span>{" "}
              Compost Machine
            </h2>
            
            <p className="text-gray-700 leading-loose md:leading-normal">
            <strong class="font-semibold">Metprop compost machine</strong> is the perfect solution for
              eco-conscious households and businesses looking to reduce waste
              and create nutrient-rich compost. Designed for efficiency and ease
              of use, our compost machine quickly breaks down organic materials
              like food scraps and garden waste, turning them into valuable
              compost for your garden or plants. With a durable, eco-friendly
              design, <strong class="font-semibold">Metprop compost machine</strong> helps minimize landfill
              waste while promoting sustainable gardening practices. Choose
              <strong class="font-semibold">Metprop compost machine</strong> for a cleaner environment and healthier, greener
              spaces with every compost cycle.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={compostMachine}
            alt="Metprop Compost Machine"
            className="w-1/2"
          />
        </div>

        <div class="h-[1px] my-[60px] bg-[#cfcfcf]"></div>
      </div>

      {/* Section End */}
      <ContactSection />

      {/* Footer start */}
      <KRFooter theme="dark" className="text-[#7F8082] bg-darkgray" />
      {/* Footer End */}
    </div>
  );
};

export default MetpropCompostMachine;
