import React from "react";

import KRFooter from "../KRFooter/KRFooter";
import { fadeIn } from "../../Functions/GlobalAnimations";
import SideButtons from "../../Components/SideButtons";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

import { imageGalleryData01 } from "./visualrenovation";

import ImageGallery from "../../Components/ImageGallery/ImageGallery";

// Header
import CommonHeader from "../CommonComponents/CommonHeader";
import ContactSection from "../ContactSection";

const RenovationElectroplating = (props) => {
  return (
    <div style={props.style}>
      {/* Header Start */}
      <CommonHeader />
      {/* Header End */}
      <SideButtons />
      {/* Parallax Section Start */}
      <m.div className="overflow-hidden py-[40px]" {...fadeIn}>
        <Container className="relative h-full">
          <Row className="justify-center h-[100px] sm:h-[250px] m-0 items-stretch w-full">
            <Col
              xl={7}
              lg={7}
              md={9}
              className="flex flex-col justify-center font-serif text-center xs:px-0"
            >
              <h6 className="text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] mb-[15px] leading-[20px] inline-block text-xmd">
                Custom Made Products From Recycled Raw Materials
              </h6>
            </Col>
          </Row>
        </Container>
      </m.div>
      {/* Parallax Section End */}

      {/* Section Start*/}
      <div className="container mx-auto px-4 mb-7">
        <div className="flex sm:flex-col items-center gap-4 space-y-4 md:space-y-0 md:space-x-6 mb-6">
          <div>
            <h2 class="text-darkgray font-light">
              <span class="inline-block font-semibold border-b-2 border-fastblue">
                Visual Renovation
              </span>{" "}
              and{" "}
              <span class="inline-block font-semibold border-b-2 border-fastblue">
                Electroplating
              </span>{" "}
              Services
            </h2>
            <p className="text-gray-700 leading-loose md:leading-normal">
              <strong class="font-semibold">Karl Ray Distribution</strong>,
              renowned both in Mauritius and internationally, has built a strong
              reputation in the delicate field of metal melting, working with
              bronze, silver, gold, and other metals. Our expertise spans a wide
              range of products, including:
            </p>
            <ul class="list-disc list-inside mb-4 text-gray-700">
              <li>Bronze arts</li>
              <li>Table arts</li>
              <li>Souvenirs</li>
              <li>Customized corporate items</li>
            </ul>
            <p className="text-gray-700 leading-loose md:leading-normal">
              We are committed to sustainability, making maximum use of recycled
              raw materials in all our processes. To further assist our clients
              in recycling their used cutlery and homewares, we offer
              specialized metal renovation services, including:
            </p>
            <ul class="list-disc list-inside mb-4 text-gray-700">
              <li>Silver and gold plating for silverware and jewelry</li>
              <li>Electrolytic plating of silverware</li>
              <li>Antique brass finishes</li>
            </ul>
            <p className="text-gray-700 leading-loose md:leading-normal">
              Our eco-friendly approach helps restore and preserve cherished
              pieces, ensuring they last for generations while supporting a
              sustainable lifestyle.
            </p>
          </div>
        </div>
        <ImageGallery
          theme="image-gallery-01"
          data={imageGalleryData01}
          overlay={["#c0c0c0"]}
          className=""
          animation={fadeIn}
        />

        <div class="h-[1px] my-[60px] bg-[#cfcfcf]"></div>
      </div>

      {/* Section End */}
      <ContactSection />

      {/* Footer start */}
      <KRFooter theme="dark" className="text-[#7F8082] bg-darkgray" />
      {/* Footer End */}
    </div>
  );
};

export default RenovationElectroplating;
