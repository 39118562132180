// All images are 800x989px

import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";
import img6 from "./img/6.png";
import img7 from "./img/7.png";
import img8 from "./img/8.png";


const imageGalleryData01 = [
    {
        src: img1,
        title: "Metal Renovation and Electroplating Services",
    },
    {
        src: img2,
        title: "Metal Renovation and Electroplating Services",
    },
    {
        src: img3,
        title: "Metal Renovation and Electroplating Services",
    },
    {
        src: img4,
        title: "Metal Renovation and Electroplating Services",
    },
    {
        src: img5,
        title: "Metal Renovation and Electroplating Services",
    },
    {
        src: img6,
        title: "Metal Renovation and Electroplating Services",
    },
    {
        src: img7,
        title: "Metal Renovation and Electroplating Services",
    },
    {
        src: img8,
        title: "Metal Renovation and Electroplating Services",
    },
];



export { imageGalleryData01 }