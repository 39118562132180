// All images are 800x989px

import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";


const imageGalleryData01 = [
    {
        src: img1,
        title: "Metprop Bin Bag 20L",
    },
    {
        src: img2,
        title: "Metprop Bin Bag 30L",
    },
    {
        src: img3,
        title: "Metprop Bin Bag 50L",
    },
    {
        src: img4,
        title: "Metprop Bin Bag 100L",
    },
   
];



export { imageGalleryData01 }