// All images are 800x989px

import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";
import img6 from "./img/6.png";
import img7 from "./img/7.png";
import img8 from "./img/8.png";
import img9 from "./img/9.png";
import img10 from "./img/10.png";
import img11 from "./img/11.png";
import img12 from "./img/12.png";


const imageGalleryData01 = [
    {
        src: img1,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img2,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img3,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img4,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img5,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img6,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img7,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img8,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img9,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img10,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img11,
        title: "Custom Made High Quality Jewellery",
    },
    {
        src: img12,
        title: "Custom Made High Quality Jewellery",
    }
];



export { imageGalleryData01 }