import React from 'react';

// Components
import { Header,HeaderNav, Menu, SearchBar } from "../../../Components/Header/Header";

// Libraries
import { Col, Navbar } from 'react-bootstrap'
import { Link } from "react-router-dom";

const CommonHeader = (props) => {
    return (
    <Header topSpace={{ desktop: true }} type="reverse-scroll">
        <HeaderNav theme="light" expand="lg" className="px-[30px] py-[0px] md:px-0" containerClass="sm:px-0">
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0 py-2">
                <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/LogoKarlRay.jpg' data-rjs='/assets/img/LogoKarlRay.jpg' alt='logo' />
                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/LogoKarlRay.jpg' data-rjs='/assets/img/LogoKarlRay.jpg' alt='logo' />
                <img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/LogoKarlRay.jpg' data-rjs='/assets/img/LogoKarlRay.jpg' alt='logo' />
              </Navbar.Brand>
            </Link>
          </Col>
          <div className="order-last hidden col-auto md:block">
            <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse className="col-auto col-lg-8 justify-center md:!px-0">
            <Menu {...props} />
          </Navbar.Collapse>
          <Col className="col-auto text-right col-lg-2 pe-0">
            <SearchBar className="pr-0 xs:p-0" />
          </Col>
        </HeaderNav>
      </Header>
      )
}

export default CommonHeader;