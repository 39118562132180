import React from "react";

import KRFooter from "../KRFooter/KRFooter";
import { fadeIn } from "../../Functions/GlobalAnimations";
import SideButtons from "../../Components/SideButtons";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

import { imageGalleryData01 } from "./visualrenovation";

import ImageGallery from "../../Components/ImageGallery/ImageGallery";

// Header
import CommonHeader from "../CommonComponents/CommonHeader";
import ContactSection from "../ContactSection";

const MetpropSolvents = (props) => {
  return (
    <div style={props.style}>
      {/* Header Start */}
      <CommonHeader />
      {/* Header End */}
      <SideButtons />
      {/* Parallax Section Start */}
      <m.div className="overflow-hidden py-[40px]" {...fadeIn}>
        <Container className="relative h-full">
          <Row className="justify-center h-[100px] sm:h-[250px] m-0 items-stretch w-full">
            <Col
              xl={7}
              lg={7}
              md={9}
              className="flex flex-col justify-center font-serif text-center xs:px-0"
            >
              <h6 className="text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] mb-[15px] leading-[20px] inline-block text-xmd">
              Premium-quality solvents for professional-grade mixability and finishes
              </h6>
            </Col>
          </Row>
        </Container>
      </m.div>
      {/* Parallax Section End */}

      {/* Section Start*/}
      <div className="container mx-auto px-4 mb-7">
        <div className="flex sm:flex-col items-center gap-4 space-y-4 md:space-y-0 md:space-x-6 mb-6">
          <div>
            <h2 class="text-darkgray font-light">
              <span class="inline-block font-semibold border-b-2 border-fastblue">
                METPROP
              </span>{" "}
              Solvents
            </h2>
            <p className="text-gray-700 leading-loose md:leading-normal">
              Our premium range of solvents, including <strong class="font-semibold">
                Metprop Thinner
              </strong>, <strong class="font-semibold">
                Metprop White Spirit
              </strong>, <strong class="font-semibold">
                Metprop Benzene
              </strong> and <strong class="font-semibold">
                Metprop Aromatic Solvent
              </strong> offer exceptional quality and professional-grade
              mixability for all your coating needs. Perfectly suited for use
              with undercoats, sealers, varnishes, primers, oil paints, lacquer
              paints, Duco paints, and enamel paints, these aromatic solvents
              ensure a smooth and flawless finish. Whether you're working on
              painting projects or need effective solutions for cleaning, our
              solvents deliver consistent performance and reliability. Choose
              our top-tier Metprop solvents for superior results in your professional and domestic
              applications.
            </p>
          </div>
        </div>
        <ImageGallery
          theme="image-gallery-01"
          data={imageGalleryData01}
          overlay={["#c0c0c0"]}
          className=""
          animation={fadeIn}
        />

        <div class="h-[1px] my-[60px] bg-[#cfcfcf]"></div>
      </div>

      {/* Section End */}
      <ContactSection />

      {/* Footer start */}
      <KRFooter theme="dark" className="text-[#7F8082] bg-darkgray" />
      {/* Footer End */}
    </div>
  );
};

export default MetpropSolvents;
