import React from "react";

import KRFooter from "../KRFooter/KRFooter";
import { fadeIn } from "../../Functions/GlobalAnimations";
import SideButtons from "../../Components/SideButtons";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

import { imageGalleryData01 } from "./visualrenovation";

import ImageGallery from "../../Components/ImageGallery/ImageGallery";

// Header
import CommonHeader from "../CommonComponents/CommonHeader";
import ContactSection from "../ContactSection";

const MetpropPaperBags = (props) => {
  return (
    <div style={props.style}>
      {/* Header Start */}
      <CommonHeader />
      {/* Header End */}
      <SideButtons />
      {/* Parallax Section Start */}
      <m.div className="overflow-hidden py-[40px]" {...fadeIn}>
        <Container className="relative h-full">
          <Row className="justify-center h-[100px] sm:h-[250px] m-0 items-stretch w-full">
            <Col
              xl={7}
              lg={7}
              md={9}
              className="flex flex-col justify-center font-serif text-center xs:px-0"
            >
              <h6 className="text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] mb-[15px] leading-[20px] inline-block text-xmd">
                Eco-friendly paper bags for sustainable everyday use."
              </h6>
            </Col>
          </Row>
        </Container>
      </m.div>
      {/* Parallax Section End */}

      {/* Section Start*/}
      <div className="container mx-auto px-4 mb-7">
        <div className="flex sm:flex-col items-center gap-4 space-y-4 md:space-y-0 md:space-x-6 mb-6">
          <div>
            <h2 class="text-darkgray font-light">
              <span class="inline-block font-semibold border-b-2 border-fastblue">
                METPROP
              </span>{" "}
              Biodegradable Paper Bags
            </h2>
            <p className="text-gray-700 leading-loose md:leading-normal">
            <strong class="font-semibold">Metprop biodegradable paper bags</strong> are the perfect eco-friendly
              alternative for carrying everyday essentials. Made from durable,
              biodegradable materials, these paper bags offer both strength and
              sustainability, making them ideal for retail, grocery, or personal
              use. Designed to be reusable and fully recyclable, our paper bags help reduce plastic waste and promote
              environmentally conscious choices. Whether for shopping or
              packaging, our paper bags provide a responsible, green solution
              that aligns with your values while maintaining quality and
              reliability. Choose Metprop for eco-friendly paper bags
              that support a greener future.
            </p>
          </div>
        </div>
        <ImageGallery
          theme="image-gallery-01"
          data={imageGalleryData01}
          overlay={["#c0c0c0"]}
          className=""
          animation={fadeIn}
        />

        <div class="h-[1px] my-[60px] bg-[#cfcfcf]"></div>
      </div>

      {/* Section End */}
      <ContactSection />

      {/* Footer start */}
      <KRFooter theme="dark" className="text-[#7F8082] bg-darkgray" />
      {/* Footer End */}
    </div>
  );
};

export default MetpropPaperBags;
