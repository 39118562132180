import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
import RenovationElectroplating from "./KarlRay/RenovationElectroplating";
import Jewellery from "./KarlRay/Jewellery";
import MetpropBinBags from "./KarlRay/MetpropBinBags";
import MetpropPaperBags from "./KarlRay/MetpropPaperBags";
import MetpropSolvents from "./KarlRay/MetpropSolvents";
import MetpropDisinfectants from "./KarlRay/MetpropDisinfectants";
import MetpropCompostMachine from "./KarlRay/MetpropCompostMachine";

// Home
const HomeComponent = lazy(() => import("./KarlRay/Home"));

// Products
const ProductsPage = lazy(() => import("./KarlRay/Products"));

// Spiffy Product Page
const SpiffyProductPage = lazy(() => import("./KarlRay/SpiffyProducts"));
const SouvenirsCorporate = lazy(() => import("./KarlRay/SouvenirsCorporate"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<HomeComponent style={{ "--base-color": "#bf8c4c" }} />} />
                  {/* <Route path="/contact" element={<ShopWidePage />} /> */}
                  <Route path="/products" element={<ProductsPage style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/spiffy-vegan-products" element={<SpiffyProductPage style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/souvenirs-corporate-items" element={<SouvenirsCorporate style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/renovation-electroplating" element={<RenovationElectroplating style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/personalised-jewellery" element={<Jewellery style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/metprop-solvents" element={<MetpropSolvents style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/metprop-disinfectants" element={<MetpropDisinfectants style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/metprop-paper-bags" element={<MetpropPaperBags style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/metprop-bin-bags" element={<MetpropBinBags style={{ "--base-color": "#bf8c4c" }} />} />
                   <Route path="/products/metprop-compost-machine" element={<MetpropCompostMachine style={{ "--base-color": "#bf8c4c" }} />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;