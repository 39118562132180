import React from "react";

import KRFooter from "../KRFooter/KRFooter";
import { fadeIn } from "../../Functions/GlobalAnimations";
import SideButtons from "../../Components/SideButtons";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

import { imageGalleryData01 } from "./visualrenovation";

import ImageGallery from "../../Components/ImageGallery/ImageGallery";

// Header
import CommonHeader from "../CommonComponents/CommonHeader";
import ContactSection from "../ContactSection";

const Jewellery = (props) => {
  return (
    <div style={props.style}>
      {/* Header Start */}
      <CommonHeader />
      {/* Header End */}
      <SideButtons />
      {/* Parallax Section Start */}
      <m.div className="overflow-hidden py-[40px]" {...fadeIn}>
        <Container className="relative h-full">
          <Row className="justify-center h-[100px] sm:h-[250px] m-0 items-stretch w-full">
            <Col
              xl={7}
              lg={7}
              md={9}
              className="flex flex-col justify-center font-serif text-center xs:px-0"
            >
              <h6 className="text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] mb-[15px] leading-[20px] inline-block text-xmd">
                Personalised Jewellery delivered within 72 hours
              </h6>
            </Col>
          </Row>
        </Container>
      </m.div>
      {/* Parallax Section End */}

      {/* Section Start*/}
      <div className="container mx-auto px-4 mb-7">
        <div className="flex sm:flex-col items-center gap-4 space-y-4 md:space-y-0 md:space-x-6 mb-6">
          <div>
            <h2 class="text-darkgray font-light">
              <span class="inline-block font-semibold border-b-2 border-fastblue">
                Personalised Jewellery
              </span>,{" "}
               Made in Mauritius
            </h2>
            <p className="text-gray-700 leading-loose md:leading-normal">
            At <strong class="font-semibold">Karl Ray Distribution</strong>, we excel in manufacturing high-quality jewellery with personalized designs, delivering exceptional craftsmanship within just 72 hours. Our expert team combines precision and artistry to create bespoke pieces that reflect your unique style and vision. From initial concept to final creation, we ensure every piece meets the highest standards of excellence, offering you a truly one-of-a-kind jewellery experience with quick turnaround.
            </p>
          </div>
        </div>
        <ImageGallery
          theme="image-gallery-01"
          data={imageGalleryData01}
          overlay={["#c0c0c0"]}
          className=""
          animation={fadeIn}
        />

        <div class="h-[1px] my-[60px] bg-[#cfcfcf]"></div>
      </div>

      {/* Section End */}
      <ContactSection />

      {/* Footer start */}
      <KRFooter theme="dark" className="text-[#7F8082] bg-darkgray" />
      {/* Footer End */}
    </div>
  );
};

export default Jewellery;
