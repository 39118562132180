const HeaderData = [
    {
        title: 'Home',
        link: '/',
    },
    {
        title: 'About',
        link: '/#about',
    },
    {
        title: 'Products',
        link: '/#products',
    },
    {
        title: 'Contact',
        link: '/#contact'
    },
]

export default HeaderData