const FooterData = [
    {
        title: "Our brands",
        submenu: [
            {
                title: "Spiffy",
                link: "/#products"
            },
            {
                title: "Metprop",
                link: "/#products"
            },
        ]
    },
    {
        title: "our network",
        submenu: [
            {
                title: "Facebook",
                link: "https://www.facebook.com/karlray.mu"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/karlray.mu/"
            },
        ]
    },
]

export default FooterData