// All images are 800x989px

import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";


const imageGalleryData01 = [
    {
        src: img1,
        title: `Metprop Disinfectants`,
    },
    {
        src: img2,
        title: `Metprop Disinfectants`
    },
    {
        src: img3,
        title: `Metprop Disinfectants` 
    },
];



export { imageGalleryData01 }