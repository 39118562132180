// All images are 800x989px

import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";


const imageGalleryData01 = [
    {
        src: img1,
        title: `Metprop Paper Bag 5"x6" (12.5cm x 15cm)`,
    },
    {
        src: img3,
        title: `Metprop Paper Bag 6"x7" (15cm x 17.5cm)` 
    },
    {
        src: img2,
        title: `Metprop Paper Bag 7"x9" (17.5cm x 22.5cm)`
    },
    {
        src: img4,
        title: `Metprop Paper Bag 8"x12" (20cm x 30cm)`
    },
   
];



export { imageGalleryData01 }