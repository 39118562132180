import React from 'react';
import { m } from "framer-motion";
import { fadeIn } from "../../Functions/GlobalAnimations";
import { Row, Col, Container } from 'react-bootstrap';
import Buttons from '../../Components/Button/Buttons'

const ContactSection = () => {
  return (
    <m.section id="contact" className="py-[80px] lg:py-[70px] md:py-[60px] sm:py-[60px] xs:py-[50px]" {...fadeIn}>
    <Container>
      <Row className='justify-center text-center'>
        <Col lg={8}>
          <h2 className="font-serif font-light mb-16 -tracking-[2px] text-darkgray"><span className="font-semibold">Talk</span> to us.</h2>
          <div className="flex justify-center xxs:flex-col xxs:items-center">
            <Buttons ariaLabel="Call Us" to="tel:+2302700413" className="hover:text-neonorange font-medium font-serif uppercase tracking-[1px] btn-transparent btn-fancy mb-[15px] !leading-[18px] mr-6" size="lg" color="#000" themeColor={["#556fff", "#ff798e"]} title="Call Us" />
            <Buttons ariaLabel="WhatsApp Us" to="https://wa.me/23052510205" className="hover:text-neonorange font-medium font-serif uppercase tracking-[1px] btn-transparent btn-fancy mb-[15px] !leading-[18px] mr-6" size="lg" color="#000" themeColor={["#556fff", "#ff798e"]} title="WhatsApp Us" />
            <Buttons ariaLabel="Facebook" to="https://www.facebook.com/karlray.mu" className="hover:text-neonorange font-medium font-serif uppercase tracking-[1px] btn-transparent btn-fancy mb-[15px] !leading-[18px] mr-6" size="lg" color="#000" themeColor={["#556fff", "#ff798e"]} title="Facebook" />
            <Buttons ariaLabel="Instagram" to="https://www.instagram.com/karlray.mu/" className="hover:text-neonorange font-medium font-serif uppercase tracking-[1px] btn-transparent btn-fancy mb-[15px] !leading-[18px]" size="lg" color="#000" themeColor={["#556fff", "#ff798e"]} title="Instagram" />
          </div>
        </Col>
      </Row>
    </Container>
  </m.section>
  );
};

export default ContactSection;
